// NAME, PHONE OR EMAIL AND MESSSAGE
import { useEffect } from "react"
export default function Form(){

    useEffect(()=>{
        var d=document,w="https://tally.so/widgets/embed.js",v=function(){"undefined" != typeof Tally ? Tally.loadEmbeds() : d.querySelectorAll("iframe[data-tally-src]:not([src])").forEach((function (e) { e.src = e.dataset.tallySrc }))};if(d.querySelector('script[src="'+w+'"]'))v();else{let s=d.createElement("script");s.src=w,s.onload=v,s.onerror=v,d.body.appendChild(s);}
    })

    return (
        <div style={{ marginTop: '50px' }}>
            <iframe data-tally-src="https://tally.so/embed/mZ9vje?transparentBackground=1&dynamicHeight=1" loading="lazy" width="100%" height="700" frameborder="0" marginheight="0" marginwidth="0" title="Drop us a message and we will be in touch soon."></iframe>
        </div>
    )
}