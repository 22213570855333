import Header from '../../components/Header/Header';
import Banner from '../../components/Banner/Banner';
import Console from '../../components/Console/Console';
import Form from '../../components/Form/Form';
import Footer from '../../components/Footer/Footer';
const Landing = () => {
    return (
        <>
            <Header />
            <Banner />
            <Console />
            <Form />
            <Footer />
        </>
    );
};

export default Landing;
