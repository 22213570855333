import Styled from './Footer.styled';
import Spotdot from '../../assets/spotdot.svg'
import { FaFacebook, FaLinkedin, FaTwitter, FaPhoneSquareAlt, FaMailBulk, FaLocationArrow } from 'react-icons/fa';
import { MdEmail, MdLocationPin } from 'react-icons/md'

export default function Footer (){

    const name={
        color:'white',
    }

    const image ={
        width:"100px",
    }
    return (
        <Styled.Wrapper>
            <Styled.Logo>
                <figure><img src={Spotdot} style={image} width={100}></img>
                    {/* <Styled.Icons>
                        <FaFacebook />
                    </Styled.Icons>
                    <Styled.Icons>
                        <FaLinkedin />
                    </Styled.Icons>
                    <Styled.Icons>
                        <FaTwitter />
                    </Styled.Icons> */}
                    <figcaption style={name}>
                        Spotdot Technologies Private Limited
                    </figcaption>
                </figure>
            </Styled.Logo>
           

            <Styled.Address>
                <Styled.IconsBottom><svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 16C1.45 16 0.979333 15.8043 0.588 15.413C0.196 15.021 0 14.55 0 14V2C0 1.45 0.196 0.979333 0.588 0.588C0.979333 0.196 1.45 0 2 0H18C18.55 0 19.021 0.196 19.413 0.588C19.8043 0.979333 20 1.45 20 2V14C20 14.55 19.8043 15.021 19.413 15.413C19.021 15.8043 18.55 16 18 16H2ZM10 9L2 4V14H18V4L10 9ZM10 7L18 2H2L10 7ZM2 4V2V14V4Z" fill="white" />
                </svg>
                </Styled.IconsBottom> contact@spotdot.in
                <br />
                <Styled.IconsBottom><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.95 18C14.8 18 12.7043 17.5207 10.663 16.562C8.621 15.604 6.81267 14.3373 5.238 12.762C3.66267 11.1873 2.396 9.379 1.438 7.337C0.479334 5.29567 0 3.2 0 1.05C0 0.75 0.0999999 0.5 0.3 0.3C0.5 0.0999999 0.75 0 1.05 0H5.1C5.33333 0 5.54167 0.0749999 5.725 0.225C5.90833 0.375 6.01667 0.566667 6.05 0.8L6.7 4.3C6.73333 4.53333 6.72933 4.74567 6.688 4.937C6.646 5.129 6.55 5.3 6.4 5.45L3.975 7.9C4.675 9.1 5.55433 10.225 6.613 11.275C7.671 12.325 8.83333 13.2333 10.1 14L12.45 11.65C12.6 11.5 12.796 11.3873 13.038 11.312C13.2793 11.2373 13.5167 11.2167 13.75 11.25L17.2 11.95C17.4333 12 17.625 12.1123 17.775 12.287C17.925 12.4623 18 12.6667 18 12.9V16.95C18 17.25 17.9 17.5 17.7 17.7C17.5 17.9 17.25 18 16.95 18ZM3.025 6L4.675 4.35L4.25 2H2.025C2.10833 2.68333 2.225 3.35833 2.375 4.025C2.525 4.69167 2.74167 5.35 3.025 6ZM11.975 14.95C12.625 15.2333 13.2877 15.4583 13.963 15.625C14.6377 15.7917 15.3167 15.9 16 15.95V13.75L13.65 13.275L11.975 14.95Z" fill="white" />
                </svg>
                </Styled.IconsBottom> +91 7015230859
                <br/>
                <Styled.IconsBottom><svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 14.475C8.65 13.1417 9.896 11.8583 10.738 10.625C11.5793 9.39167 12 8.23333 12 7.15C12 6.21667 11.8293 5.42067 11.488 4.762C11.146 4.104 10.725 3.57067 10.225 3.162C9.725 2.754 9.18333 2.45833 8.6 2.275C8.01667 2.09167 7.48333 2 7 2C6.51667 2 5.98333 2.09167 5.4 2.275C4.81667 2.45833 4.275 2.754 3.775 3.162C3.275 3.57067 2.85433 4.104 2.513 4.762C2.171 5.42067 2 6.21667 2 7.15C2 8.23333 2.42067 9.39167 3.262 10.625C4.104 11.8583 5.35 13.1417 7 14.475ZM7 17C4.65 15.2667 2.89567 13.5833 1.737 11.95C0.579 10.3167 0 8.71667 0 7.15C0 5.96667 0.212667 4.929 0.638 4.037C1.06267 3.14567 1.60833 2.4 2.275 1.8C2.94167 1.2 3.69167 0.75 4.525 0.45C5.35833 0.15 6.18333 0 7 0C7.81667 0 8.64167 0.15 9.475 0.45C10.3083 0.75 11.0583 1.2 11.725 1.8C12.3917 2.4 12.9377 3.14567 13.363 4.037C13.7877 4.929 14 5.96667 14 7.15C14 8.71667 13.4207 10.3167 12.262 11.95C11.104 13.5833 9.35 15.2667 7 17ZM7 9C7.55 9 8.021 8.804 8.413 8.412C8.80433 8.02067 9 7.55 9 7C9 6.45 8.80433 5.979 8.413 5.587C8.021 5.19567 7.55 5 7 5C6.45 5 5.97933 5.19567 5.588 5.587C5.196 5.979 5 6.45 5 7C5 7.55 5.196 8.02067 5.588 8.412C5.97933 8.804 6.45 9 7 9ZM0 20V18H14V20H0Z" fill="white" />
                </svg>
                </Styled.IconsBottom> Gurugram, India
            </Styled.Address>
            
            
        </Styled.Wrapper>
    )
}