export default function DataCollection() {
    return (
        <>
            <div className="mx-10 my-10">
                <strong>App Permissions</strong>
                <p>
                    <br />
                    1. Camera:
                    <br />
                    Camera access is simply required so that you can scan a spot.
                    <br />
                    2. Internet:
                    <br />
                    Can't really do much without internet. The app needs to be connected to the internet for things like scanning, saving your data etc.
                </p>

            </div>
        </>
    )
}