import styled, { keyframes } from 'styled-components';


const Image = styled.div`
   width:200px;
   margin:auto;
   margin-top:120px;
   margin-bottom:30px;
    @media (max-width: 768px) {
   margin-top:90px;
  }
  ${'' /* @media (min-height: 1080px) {
   width:700px;
  } */}
`;

const Caption = styled.div`
    text-align:center;
    font-weight:normal;
    font-size:30px;
`;

const ColoredText = styled.span`
    color:#FF5447;
`;

const SubCaption = styled.div`
    margin-top:10px;
    font-size:22px;
`;

const Wrapper = styled.div`
    display:flex;
    align-items:center;
    flex-direction:column;
    margin-bottom:30px;
    ${'' /* @media (min-height: 1080px) {
        position:relative;
        top:30vh;
    } */}
`;

const styles = {
    Image,
    Caption,
    ColoredText,
    Wrapper,
    SubCaption
}

export default styles;