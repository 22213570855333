import { useEffect } from 'react';

export default function AppSignupPage() {
  
  useEffect(() => {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.spotdot.spotdot';
  }, []);


  return (
    <div>
      <h1>Hi there!</h1>
      <p>You will be redirected to the Google Play Store shortly.</p>
    </div>
  );
}