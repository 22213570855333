import { useState, useEffect } from 'react';
import TextTransition, { presets } from "react-text-transition";
import Styled from './Banner.styled';

const TEXTS = [
  "company",
  "studio",
  "store",
  "people"
];

const COLORS = [
  '#41B3FD',
  '#BA77D9',
  '#77D9B6',
  '#B23A3A'
]

const Banner = () => {
    const [index, setIndex] = useState(0);

    const colors = {
      color : COLORS[index % COLORS.length],
    }

    useEffect(() => {
    const intervalId = setInterval(() =>
      setIndex(index => index+=1),
      3000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);
    return (
        <Styled.BannerWpr>
            <Styled.BoxHeading>
                <Styled.Heading>
                    secure contactless experiences<Styled.Transition> for your&nbsp;<Styled.Animation> <TextTransition style={colors} springConfig={presets.stiff}>
        {TEXTS [index % TEXTS.length] } 
      </TextTransition>
      </Styled.Animation> 
       </Styled.Transition> 
                </Styled.Heading>
                <Styled.DescHeadingOne>
                    Let your people move in and out of places contactlessly with their phones. <br />
                    Integrate powerful contactless experiences into your business with spotdot.
                </Styled.DescHeadingOne>
            </Styled.BoxHeading>
        </Styled.BannerWpr>
    );
};

export default Banner;
