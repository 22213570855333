import styled, { keyframes } from 'styled-components';


const Wrapper = styled.div`
    background:#212121;
    overflow-Y:hidden;
    overflow-X:hidden;
    padding-bottom:16px;
`;

const Logo = styled.div`
    width:fit-content;
    display:inline-block;
    margin-left:20px;
    margin-top:30px;
    margin-bottom:20px;
`;

const Icons = styled.span`
    color:white;
    margin-left: 10px;
    font-size:20px;
    margin-right:10px;
    position:relative;
    bottom:4px;
`;

const IconsBottom = styled.span`
    width:20px;
    color:white;
    margin-left: 10px;
    font-size:20px;
    margin-right:10px;
    position:relative;
    ${'' /* top:3px; */}
    left:7px;
`;



const Address = styled.div`
    color:white;
`;



const styles = {
   Wrapper,
   Logo,
   Icons,
   Address,
   IconsBottom
}

export default styles;