//? ESSENTIAL IMPORTS
import { Routes, Route } from 'react-router-dom';

//? COMPONENT IMPORTS
import Landing from './pages/Landing/Landing';
import TermsAndConditions from './pages/Terms_Conditions/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import DataCollection from './pages/DataCollection/DataCollection';
import AppPermissions from './pages/AppPermissions/AppPermissions';
import AppSignupPage from './pages/AppSignupPage/AppSignupPage';
//? STYLES IMPORTS
import Styled from './App.styled';

const App = () => {
    return (
        <Styled.ThemeWrapper>
            <Routes>
                <Route path='/' element={<Landing />} />
                <Route path='/terms_and_conditions' element={<TermsAndConditions />} />
                <Route path='/privacy_policy' element={<PrivacyPolicy />} />
                <Route path='/data_collection' element={<DataCollection />} />
                <Route path='/app_permissions' element={<AppPermissions />} />
                <Route path='/appsignup' element={<AppSignupPage />} />
            </Routes>
        </Styled.ThemeWrapper>
    );
};

export default App;